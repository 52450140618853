import React from "react";

import Nav from "../../components/nav";
import SEO from "../../components/seo";
import newsletters from "../../newsletters";
import "../../css/style.css";

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      issue: newsletters[0]
    };

    this.iframe = React.createRef();
    this.select = React.createRef();
  }

  onChange = e => {
    const { value } = e.target;
    this.setState({
      issue: newsletters.filter(
        // eslint-disable-next-line
        item => item.issue == value
      )[0]
    });
  };

  componentDidMount() {
    if (this.props.location.hash.startsWith("#issue=")) {
      const requestedIssue = this.props.location.hash.split("#issue=")[1];
      const potentialNewsletter = newsletters.filter(
        // eslint-disable-next-line
        item => item.issue == requestedIssue
      );

      if (potentialNewsletter.length === 1) {
        this.setState({ issue: potentialNewsletter[0] });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.issue.issue !== this.state.issue.issue) {
      document.location.hash = `#issue=${this.state.issue.issue}`;
    }
  }

  render() {
    const { issue } = this.state;

    return (
      <>
        <SEO
          title={"Archive"}
          keywords={[
            "Electron",
            "newsletter",
            "apps",
            "job offers",
            "node libraries"
          ]}
        />

        <header className="archive">
          <h1>Electron newsletter Archive</h1>

          <div className="selector">
            <select
              id="month"
              name="month"
              onChange={this.onChange}
              ref={this.select}
              value={issue.issue}
            >
              {newsletters.map(item => (
                <option
                  value={item.issue}
                  key={item.issue}
                  data-url={item.link}
                >
                  Issue {item.issue}, {item.title}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>

          <Nav />
        </header>

        <iframe ref={this.iframe} src={issue.link} title="newsletter viewer" />
      </>
    );
  }
}
